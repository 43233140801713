// next
// icons
import directionStraightRight from '@iconify/icons-carbon/direction-straight-right';
import Masonry from '@mui/lab/Masonry';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
// @mui
import { styled } from '@mui/material';
import NextLink from 'next/link';
import { ArticleProps } from 'src/@types/article';
import Image from 'src/components/Image';
// components
import { BgOverlay, Iconify, TextMaxLine } from '../../components';
// hooks
import useResponsive from '../../hooks/useResponsive';
// routes
import Routes from '../../routes';
// utils
import { fDate } from '../../utils/formatTime';
//
import BlogPostItemMobile from './BlogPostItemMobile';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  articles: ArticleProps[];
};

export default function BlogLatestPosts({ articles }: Props) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <RootStyle>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{
            mb: { xs: 8, md: 10 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Stack
            sx={{
              maxWidth: { md: 460 },
            }}
          >
            <Typography variant="overline" sx={{ color: 'text.disabled' }}>
              Aktuelt
            </Typography>
            <Typography variant="h2" sx={{ mt: 2, mb: 3 }}>
              Les nylige artikler
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>
              Aenean vulputate eleifend tellus. Mauris turpis nunc, blandit et, volutpat molestie,
              porta ut, ligula.
            </Typography> */}
          </Stack>

          <NextLink href={Routes.blog} passHref legacyBehavior>
            <Button
              endIcon={<Iconify icon={directionStraightRight} sx={{ width: 22, height: 22 }} />}
              sx={{ display: { xs: 'none', md: 'inline-flex' } }}
            >
              Se alle artikler
            </Button>
          </NextLink>
        </Stack>

        <Box
          sx={{
            display: 'grid',
            gap: { xs: 3, md: 4 },
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
          }}
        >
          {isDesktop ? (
            <>
              <PostItem article={articles[0]} largePost />

              <Masonry columns={{ xs: 1, md: 2 }} spacing={4}>
                {articles.slice(1, 5).map((article, index) => (
                  <PostItem key={article.slug.current} article={article} order={index % 2} />
                ))}
              </Masonry>
            </>
          ) : (
            <>
              {articles.slice(0, 5).map((article) => (
                <BlogPostItemMobile
                  key={article.slug.current}
                  article={article}
                  path={Routes.blog}
                />
              ))}
            </>
          )}
        </Box>

        <Stack
          alignItems="center"
          sx={{
            mt: 8,
            display: { xs: 'flex', md: 'none' },
          }}
        >
          <NextLink href={Routes.blog} passHref legacyBehavior>
            <Button
              endIcon={<Iconify icon={directionStraightRight} sx={{ width: 22, height: 22 }} />}
            >
              Se alle
            </Button>
          </NextLink>
        </Stack>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type PostItemProps = {
  article: ArticleProps;
  order?: number;
  largePost?: boolean;
};

function PostItem({ article, order, largePost }: PostItemProps) {
  const { title, slug, header, description, date } = article;

  return (
    <Stack
      spacing={2}
      sx={{
        ...(largePost && {
          borderRadius: 2,
          overflow: 'hidden',
          position: 'relative',
        }),
      }}
    >
      <Box sx={{ position: 'relative', aspectRatio: '1/1' }}>
        <NextLink as={Routes.post(slug.current)} href={Routes.post('[slug]')} passHref>
          <Image
            src={header}
            alt={title}
            sizes={{ xs: 1, md: 3 }}
            style={{ borderRadius: '16px', objectFit: 'cover' }}
          />
        </NextLink>
      </Box>

      <Stack
        spacing={largePost ? 2 : 1}
        sx={{
          ...(largePost && {
            p: 5,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            color: 'common.white',
          }),
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            typography: 'caption',
            color: 'text.disabled',
            ...(largePost && {
              opacity: 0.72,
              color: 'inherit',
            }),
          }}
        >
          {fDate(date)}
        </Stack>

        <NextLink
          passHref
          as={Routes.post(slug.current)}
          href={Routes.post('[slug]')}
          legacyBehavior
        >
          <TextMaxLine variant={largePost ? 'h3' : 'h6'} asLink>
            {title}
          </TextMaxLine>
        </NextLink>

        {largePost && <Typography sx={{ opacity: 0.48 }}>{description}</Typography>}
      </Stack>

      {largePost && <BgOverlay />}
    </Stack>
  );
}
